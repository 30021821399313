import LiveChatIcon from './LiveChatIcon';

type Props = {
  title: string;
  subtitle?: string;
  onClick?: () => void;
};

const LiveChatButtonClientDesktop: FC<Props> = ({
  title,
  subtitle,
  onClick,
}) => (
  <div
    className={`group grid grid-flow-col items-center justify-between cursor-pointer`}
    onClick={onClick}
  >
    <span
      className="grid grid-flow-col grid-cols-[minmax(2rem,1fr)] items-center gap-3"
      data-gtm-event-category="Menu_item"
      data-gtm-event-action="sidebar_menu"
      data-gtm-event-info={title}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full sm:bg-goku transition duration-200 group-hover:scale-110">
        <LiveChatIcon className="text-goten" width={22} height={22} />
      </div>
      <div className="grid">
        <div className="flex gap-1 items-center">
          <p className="text-moon-16 font-light text-bulma transition duration-200 group-hover:text-piccolo">
            {title}
          </p>
        </div>

        <p className="text-moon-12 text-raditz w-full">{subtitle}</p>
      </div>
    </span>
  </div>
);

export default LiveChatButtonClientDesktop;
