'use client';

import useProtoV3 from './hooks/useProtoV3';
import LiveChatButtonDesktopClient from './LiveChatButtonClientDesktop';
import LiveChatButtonMobileClient from './LiveChatButtonClientMobile';

type Props = {
  title: string;
  subtitle?: string;
  isMobile?: boolean;
};

const LiveChatButtonClient: FC<Props> = ({ title, subtitle, isMobile }) => {
  const { handleClick } = useProtoV3();

  if (isMobile) {
    return <LiveChatButtonMobileClient onClick={handleClick} text={subtitle} />;
  }

  return (
    <LiveChatButtonDesktopClient
      title={title}
      subtitle={subtitle}
      onClick={handleClick}
    />
  );
};

export default LiveChatButtonClient;
