const LiveChatIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83335 9.99996C5.83335 9.53971 6.20645 9.16663 6.66669 9.16663H6.67416C7.1344 9.16663 7.5075 9.53971 7.5075 9.99996C7.5075 10.4602 7.1344 10.8333 6.67416 10.8333H6.66669C6.20645 10.8333 5.83335 10.4602 5.83335 9.99996Z"
      fill="currentColor"
    />
    <path
      d="M9.16295 9.99996C9.16295 9.53971 9.53602 9.16663 9.99627 9.16663H10.0038C10.464 9.16663 10.8371 9.53971 10.8371 9.99996C10.8371 10.4602 10.464 10.8333 10.0038 10.8333H9.99627C9.53602 10.8333 9.16295 10.4602 9.16295 9.99996Z"
      fill="currentColor"
    />
    <path
      d="M13.3259 9.16663C12.8656 9.16663 12.4925 9.53971 12.4925 9.99996C12.4925 10.4602 12.8656 10.8333 13.3259 10.8333H13.3334C13.7936 10.8333 14.1667 10.4602 14.1667 9.99996C14.1667 9.53971 13.7936 9.16663 13.3334 9.16663H13.3259Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.04169 9.63888C1.04169 4.86528 5.07938 1.04163 10 1.04163C14.9207 1.04163 18.9584 4.86528 18.9584 9.63888C18.9584 14.4125 14.9207 18.2361 10 18.2361C9.4201 18.2369 8.84198 18.1832 8.2725 18.0763C8.07484 18.0391 7.94926 18.0157 7.85582 18.003C7.81535 17.9974 7.79026 17.9953 7.77657 17.9945L7.78856 17.99C7.78856 17.99 7.78349 17.9911 7.77482 17.9925L7.76789 17.9934C7.77003 17.9935 7.77266 17.9942 7.77657 17.9945C7.76243 17.9999 7.73496 18.011 7.68981 18.0325C7.59437 18.0779 7.46725 18.1453 7.27264 18.2488C6.07954 18.8833 4.68755 19.1083 3.34499 18.8585C3.1282 18.8182 2.94869 18.6666 2.87263 18.4597C2.79657 18.2527 2.83522 18.021 2.97433 17.8499C3.36416 17.3704 3.63221 16.7927 3.75075 16.171C3.78283 16 3.71025 15.7677 3.48711 15.5411C1.97479 14.0054 1.04169 11.9286 1.04169 9.63888ZM10 2.29163C5.71696 2.29163 2.29169 5.60725 2.29169 9.63888C2.29169 11.5798 3.08081 13.347 4.37774 14.664C4.77293 15.0653 5.11339 15.691 4.979 16.4031L4.9788 16.4042C4.89222 16.8588 4.7449 17.2971 4.5421 17.707C5.28697 17.6894 6.02444 17.4968 6.68572 17.1451L6.69861 17.1383L6.70141 17.1368C6.87852 17.0426 7.02997 16.9621 7.15354 16.9034C7.27369 16.8463 7.42369 16.7816 7.58609 16.7568C7.74423 16.7326 7.89786 16.747 8.02573 16.7645C8.15349 16.782 8.30978 16.8114 8.48857 16.845L8.50323 16.8477C8.99654 16.9404 9.49719 16.9868 9.99919 16.9861C14.2823 16.9861 17.7084 13.6705 17.7084 9.63888C17.7084 5.60725 14.2831 2.29163 10 2.29163Z"
      fill="currentColor"
    />
  </svg>
);

export default LiveChatIcon;
